<template>
  <item-list-view
    ref="list"
    :api="api"
    :headers="headers"
    id-key="id"
    title="Документы"
    :allowed-search="true"
    :allowed-create="true"
    :create-route="getRoute()"
    :allowed-remove="true"
    update-route="Document"
    disable-pagination
    remove-title="Удаление документа"
    remove-message="Вы действительно хотите удалить документ?"
  >
    <template v-slot:item.title="{ item }">
      <router-link :to="getRoute(item.id)">
        {{ item.name }}
      </router-link>
    </template>
    <template v-slot:item.updatedAt="{ item }">
      {{ item.createdAt | dateTime }}
    </template>
    <template v-slot:item.createdAt="{ item }">
      {{ item.createdAt | dateTime }}
    </template>
  </item-list-view>
</template>

<script>
import api from '../../services/documents'

export default {
  components: {
  },
  data () {
    return {
      api,
      headers: [
        { text: 'Код', value: 'code' },
        { text: 'Название', value: 'title' },
        { text: 'Загружен', value: 'createdAt' },
        { text: 'Изменен', value: 'updatedAt' },
        { text: '', value: 'action' }
      ],
    }
  },
  methods: {
    getRoute (id) {
      return { name: 'Document', params: { id: id || 'new' } }
    }
  }
}
</script>

<!-- <template>
  <item-list-view
    ref="list"
    :api="api"
    :headers="headers"
    id-key="id"
    title="Файлы"
    :allowed-search="true"
    :allowed-create="false"
    :allowed-remove="true"
    update-route="File"
    remove-title="Удаление файла"
    remove-message="Вы действительно хотите удалить файл?"
  >
    <template v-slot:before-table>
      <v-flex style="width: 100%">
        <vue-clip :options="options" class="uploader" :on-complete="onUploadComplete">
          <template slot="clip-uploader-body" scope="props">
            <div class="uploader-files">
              <div class="uploader-file" v-for="file in props.files">
                <div class="file-avatar" v-if="file.dataUrl">
                  <img v-bind:src="file.dataUrl"/>
                </div>
                <div class="file-details">
                  <div class="file-name">{{ file.name }}</div>
                  <div class="file-progress" v-if="file.status !== 'error' && file.status !== 'success'">
                    <progress class="progress is-primary" :value="file.progress" max="100"></progress>
                  </div>
                  <div class="file-meta" v-else>
                    <span class="file-size">{{ file.size }}</span>
                    <span class="file-status">{{ file.status }}</span>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template slot="clip-uploader-action" scope="props">
            <div class="uploader-action" v-bind:class="{dragging: props.dragging}">
              <div class="dz-message has-text-centered">
                Выберите файл или перетащите сюда
              </div>
            </div>
          </template>
        </vue-clip>
      </v-flex>
    </template>

    <template v-slot:item.title="{ item }">
      <div v-if="item.title" class="text-body-1 mb-2">{{ item.title }}</div>
      <div>{{ item.id }}</div>
    </template>
    <template v-slot:item.createdAt="{ item }">
      {{ item.createdAt | dateTime }}
    </template>
  </item-list-view>
</template>

<script>
import config from '../../config'
import api from '../../services/files'
import ItemListView from '../../components/ItemListView'
import debounce from 'lodash/debounce'

export default {
  components: { ItemListView },
  data: () => ({
    api: api,
    headers: [
      { text: 'Название', value: 'title' },
      { text: 'Дата загрузки', value: 'createdAt' },
      { text: '', value: 'action' }
    ],
    options: {
      url: config.fileUploadUrl,
      maxFilesize: 15,
    }
  }),
  methods: {
    fetchDebounce: debounce(function() {
      this.$refs.list.searchDebounce()
    }, 500),
    onUploadComplete (file, status, xhr) {
      switch (status) {
        case 'success':
          this.fetchDebounce()
          break
        case 'error':
          const resp = JSON.parse(xhr.responseText)
          this.$notify({
            title: `Ошибка (${resp.code || xhr.status})`,
            message: resp.message || '',
            type: 'error',
            duration: 3000
          })
          break
      }
    }
  }
}
</script>

<style lang="scss">
    .uploader {
        display: flex;
        flex-direction: column-reverse;
    }

    .uploader * {
        box-sizing: border-box;
    }

    .uploader-action {
        margin:  20px 0;
        background: #F5F5F5FF;
        transition: background 300ms easy;

        .dz-message {
            cursor: pointer;
            padding: 20px 40px;
            border: 2px dashed #00D1B2FF;
            border-radius: 4px;
        }

        &.dragging {
            background: #fff;
        }
    }

    .uploader-files {
        flex: 1;
    }

    .uploader-file {
        display: flex;

        .file-avatar {
            margin-right: 20px;
        }

        .file-details {
            width: 100%;

            .file-status {
                text-transform: uppercase;
                margin-left: 1rem;
                font-weight: 600;
            }
            .file-progress {
                margin: 5px 0;
            }
        }
    }
</style> -->
