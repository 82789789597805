import BaseService from './baseService'
import {
  tagsList,
  typesMeta
} from '../consts/document'

class DocumentsService extends BaseService {
  get url () {
    return 'documents'
  }

  get tags () {
    return tagsList
  }

  get types () {
    return Object.entries(typesMeta).map(([type, meta]) => ({
      type,
      ...meta
    }))
  }

  createModel () {
    return {
      id: null,
      name: null,
      code: null,
      createdAt: null,
      updatedAt: null,
      type: null,
      clinicIds: [],
      fileItems: [],
      tags: []
    }
  }

  toDto (item) {
    return {
      name: item.name,
      type: item.type,
      code: item.code,
      clinicIds: item.clinicIds,
      tags: item.tags || [],
      fileItems: item.fileItems.map(({ endDate, startDate, file }) => ({
        endDate,
        startDate,
        fileId: file.id
      })),
    }
  }
}

export default new DocumentsService()

